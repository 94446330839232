import React, { useEffect, useState } from 'react'
import {
    Form,
    Input,
    Button,
    Select,
    Radio,
    DatePicker,
    Message,
    Space,
    Cascader,
} from 'antd'
import UserSelector from '@/components/userSelector'
import ProductSelector from '@/components/productSelector'
import CouponSelector from '@/components/couponSelector'
import StoreSelector from '@/components/storeSelector'
import moment from 'moment'
import { getCategoryList, getNumeralCouponDetail, numeralCouponAdd } from '@/apis/mealCoupon'
import { getStoreDetail } from '@/apis/shop'
import qs from 'query-string'
import './index.less'

const validators = {
    money: (_, value) => {
        if (!value) {
            return Promise.reject(new Error('请输入金额'))
        } else if (+value < 0) {
            return Promise.reject(new Error('金额不能小于0'))
        }
        return Promise.resolve()
    },
    money2: (_, value) => {
        if (!value) {
            return Promise.reject(new Error('请输入金额'))
        } else if (+value < 0 || +value > 1) {
            return Promise.reject(new Error('请输入0-1的值'))
        }
        return Promise.resolve()
    },
    max_num: (_, value) => {
        if (!value) {
            return Promise.reject(new Error('请输入发行数量'))
        } else if (!Number.isInteger(+value)) {
            return Promise.reject(new Error('请输入整数'))
        } else if (isNaN(+value) || +value < 1000 || +value > 10000000) {
            return Promise.reject(new Error('发行数量需大于等于1000，且不超过1,000万'))
        }
        return Promise.resolve()
    },
    limit_number: (_, value) => {
        if (!value) {
            return Promise.reject(new Error('请输入每人限领'))
        } else if (!Number.isInteger(+value)) {
            return Promise.reject('请输入整数')
        } else if (isNaN(+value) || +value < 1 || +value > 100) {
            return Promise.reject(new Error('请输入大于0且小于等于100的整数'))
        }
        return Promise.resolve()
    },
}

const AddNumeralCoupon = (props) => {
    const { history } = props;
    const { id } = qs.parse(history.location.search)
    const [form] = Form.useForm()
    const [info, setInfo] = useState()
    const [options, setOptions] = useState([])

    useEffect(() => {
        if (id) {
            getData()
        } else {
            loadChildren(0).then(data => {
                setOptions(data)
            })
        }
        getStoreDetail().then(({ data }) => {
            setInfo(data)
        })
    }, [])

    const getData = async() => {
        try {
            const { data } = await getNumeralCouponDetail({ id })
            form.setFieldsValue({
                type: data.type,
                money: data.money,
                full: data.full,
                max_num: data.max_num + '',
                name: data.name,
                date: [moment(data.start_time, 'YYYY-MM-DD HH:mm:ss'), moment(data.end_time, 'YYYY-MM-DD HH:mm:ss')],
                is_effective: data.is_effective,
                effective_end_time: data.is_effective === 1 ? moment(data.effective_end_time, 'YYYY-MM-DD HH:mm:ss') : undefined,
                effective_time: data.is_effective === 2 ? [
                    moment(data.effective_start_time, 'YYYY-MM-DD HH:mm:ss'),
                    moment(data.effective_end_time, 'YYYY-MM-DD HH:mm:ss')
                ] : [null, null],
                use_time_type: data.use_time_type,
                use_time_number: data.use_time_number,
                usable_store: data.usable_store,
                usable_store_arr: data.usable_store === 2 ? data.usable_store_arr.map(t => ({
                    id: t.store_id,
                    name: t.store_name
                })) : [],
                usable_goods: data.usable_goods,
                usable_goods_arr: [2, 3].includes(data.usable_goods) ? data.usable_goods_arr.map(t => ({
                    id: t.product_id,
                    name: t.product_name
                })) : [],
                usable_goods_category_id: data.usable_goods === 4 ? data.usable_goods_category_id : [],
                is_hand: data.is_hand,
                usable_user: data.usable_user,
                usable_user_goods_arr: data.usable_user === 3 ? data.usable_user_goods_arr.map(t => ({
                    id: t.product_id,
                    name: t.product_name
                })) : [],
                usable_user_specify_arr: data.usable_user === 6 ? data.usable_user_specify_arr.map(t => ({
                    id: t.id,
                    username: t.nickname
                })) : [],
                limit_number: data.limit_number,
                stacking_coupon_arr: data.stacking_coupon_arr || [],
            })
            const opts = await loadChildren(0)
            for (let index = 0; index < data.usable_goods_category_id.length; index++) {
                const arr = data.usable_goods_category_id[index]
                let item = null
                for (let i = 0; i < arr.length; i++) {
                    item = (item ? item.children : opts).find(t => t.value === arr[i])
                    if (item && !item.isLeaf && !item.children) {
                        const o = await loadChildren(arr[i])
                        item.children = o
                    }
                }
            }
            setOptions(opts)
        } catch (e) {
            console.log(e)
        }
    }

    const loadChildren = async(id) => {
        try {
            const { data } = await getCategoryList({ id: 1, parent_id: id })
            return data.map(t => ({
                value: t.id,
                label: t.name,
                isLeaf: !t.hasChildren
            }))
        } catch (e) {
            return []
        }
    }

    const loadData = (selectedOptions) => {
        const targetOption = selectedOptions[selectedOptions.length - 1]
        targetOption.loading = true
        getCategoryList({ id: 1, parent_id: targetOption.value }).then(({ data }) => {
            targetOption.loading = false
            targetOption.children = data.map(t => ({
                value: t.id,
                label: t.name,
                isLeaf: !t.hasChildren
            }))
            setOptions([...options])
        })
    }

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().startOf('day');
    }

    const onTimeTypeChange = () => {
        form.setFieldsValue({ use_time_number: undefined })
    }

    const onSubmit = async(values) => {
        try {
            const { date, effective_time, effective_end_time, ..._values } = values
            const params = {
                ..._values,
                category_type: 1,
                start_time: `${date[0].format('YYYY-MM-DD')} 00:00:00`,
                end_time: `${date[1].format('YYYY-MM-DD')} 23:59:59`,
                effective_start_time: values.is_effective === 2
                    ? `${effective_time[0].format('YYYY-MM-DD')} 00:00:00`
                    : undefined,
                effective_end_time: values.is_effective === 2
                    ? `${effective_time[1].format('YYYY-MM-DD')} 23:59:59`
                    : `${effective_end_time.format('YYYY-MM-DD')} 23:59:59`,
                usable_store: values.usable_store || 1,
                usable_store_arr: values.usable_store === 2 ? values.usable_store_arr.map(t => ({
                    store_id: t.id,
                    store_name: t.name
                })) : undefined,
                usable_goods_arr: [2, 3].includes(values.usable_goods) ? values.usable_goods_arr.map(t => ({
                    product_id: t.id,
                    product_name: t.name
                })) : undefined,
                usable_goods_category_id: values.usable_goods === 4 ? values.usable_goods_category_id.map(t => t[t.length - 1]) : undefined,
                usable_user_goods_arr: values.usable_user === 3 ? values.usable_user_goods_arr.map(t => ({
                    product_id: t.id,
                    product_name: t.name
                })) : undefined,
                usable_user_specify_arr: values.usable_user === 6 ? values.usable_user_specify_arr.map(t => ({
                    id: t.id,
                    nickname: t.username
                })) : undefined,
            }
            const { data } = await numeralCouponAdd(params)
            Message.success('提交成功');
            setTimeout(() => {
                history.go(-1);
            }, 2000);
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="addNumeralCoupon">
            <Form form={form} colon={false} onFinish={onSubmit} disabled={!!id} autoComplete="off">
                {(data) => (<>
                    <div className="block-title">面额信息</div>
                    <Form.Item label="优惠券类型" required style={{ marginBottom: 0 }}>
                        <Form.Item name="type" initialValue={1} rules={[{ required: true }]}>
                            <Radio.Group>
                                <Radio value={1}>满减</Radio>
                                <Radio value={2}>折扣</Radio>
                            </Radio.Group>
                        </Form.Item>
                        {data.type === 1 && (<Form.Item label="满减金额">
                            <Space>
                                <span>减</span>
                                <Form.Item name="money" rules={[{ required: true, validator: validators.money }]} noStyle>
                                    <Input type="number" placeholder="请输入金额" style={{ width: '110px' }} />
                                </Form.Item>
                                <span>元</span>
                            </Space>
                        </Form.Item>)}
                        {data.type === 2 && (<Form.Item label="折扣率">
                            <Form.Item name="money" rules={[{ required: true, validator: validators.money2 }]} noStyle>
                                <Input type="number" placeholder="如8折为0.8" style={{ width: '110px' }} />
                            </Form.Item>
                        </Form.Item>)}
                        <Form.Item label="使用门槛">
                            <Space>
                                <span>满</span>
                                <Form.Item name="full" rules={[{ required: true, validator: validators.money }]} noStyle>
                                    <Input type="number" placeholder="请输入金额" style={{ width: '110px' }} />
                                </Form.Item>
                                <span>元使用</span>
                            </Space>
                            <div className="form-item-tips">满0元使用即无门槛使用</div>
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label="发行数量" required>
                        <Space>
                            <Form.Item name="max_num" rules={[{ required: true, validator: validators.max_num }]} noStyle>
                                <Input type="number" placeholder="需大于等于1000，不超过1,000万" style={{ width: '234px' }} />
                            </Form.Item>
                            <span>张</span>
                        </Space>
                    </Form.Item>
                    <div className="block-title">基本信息</div>
                    <Form.Item label="优惠券标题" name="name" rules={[{ required: true, message: '请输入优惠券标题' }]}>
                        <Input placeholder="请输入25字以内优惠券标题" maxLength={25} style={{ width: '260px' }} />
                    </Form.Item>
                    <Form.Item label="活动时间" name="date" rules={[{ required: true, message: '请选择活动时间' }]}>
                        <DatePicker.RangePicker disabledDate={disabledDate} />
                    </Form.Item>
                    <Form.Item label="生效时间" required>
                        <Space>
                            <Form.Item name="is_effective" initialValue={1} rules={[{ required: true }]} noStyle>
                                <Radio.Group>
                                    <Radio value={1}>领取后生效</Radio>
                                    <Radio value={2}>固定时间</Radio>
                                </Radio.Group>
                            </Form.Item>
                            {data.is_effective === 1 && (
                                <Form.Item name="effective_end_time" rules={[{ required: true, message: '请选择截止日期' }]} noStyle>
                                    <DatePicker disabledDate={disabledDate} placeholder="请选择截止日期" />
                                </Form.Item>
                            )}
                            {data.is_effective === 2 && (
                                <Form.Item name="effective_time" rules={[{ required: true, message: '请选择生效日期' }]} noStyle>
                                    <DatePicker.RangePicker disabledDate={disabledDate} />
                                </Form.Item>
                            )}
                        </Space>
                    </Form.Item>
                    <Form.Item label="使用时间" required>
                        <Space>
                            <Form.Item name="use_time_type" initialValue={1} rules={[{ required: true }]} noStyle>
                                <Radio.Group onChange={onTimeTypeChange}>
                                    <Radio value={1}>不限</Radio>
                                    <Radio value={2}>每周</Radio>
                                    <Radio value={3}>每月</Radio>
                                </Radio.Group>
                            </Form.Item>
                            {data.use_time_type === 2 && (
                                <Form.Item name="use_time_number" rules={[{ required: true, message: '请选择星期几' }]} noStyle>
                                    <Select
                                        style={{ width: 120 }}
                                        placeholder="选择星期几"
                                        options={[
                                            { value: 1, label: '星期一' },
                                            { value: 2, label: '星期二' },
                                            { value: 3, label: '星期三' },
                                            { value: 4, label: '星期四' },
                                            { value: 5, label: '星期五' },
                                            { value: 6, label: '星期六' },
                                            { value: 7, label: '星期日' },
                                        ]}
                                    />
                                </Form.Item>
                            )}
                            {data.use_time_type === 3 && (
                                <Form.Item name="use_time_number" rules={[{ required: true, message: '请选择日期' }]} noStyle>
                                    <Select
                                        style={{ width: 120 }}
                                        placeholder="选择日期"
                                        options={Array.from({ length: 30 }).map((t, index) => ({ value: index +1, label: `${index+1}号` }))}
                                    />
                                </Form.Item>
                            )}
                        </Space>
                    </Form.Item>
                    {!!info && info.is_main === 1 && <Form.Item label="可用门店" required style={{ marginBottom: 0 }}>
                        <Form.Item name="usable_store" initialValue={1} rules={[{ required: true }]}>
                            <Radio.Group>
                                <Radio value={1}>全部可用</Radio>
                                <Radio value={2}>指定门店</Radio>
                            </Radio.Group>
                        </Form.Item>
                        {data.usable_store === 2 && <Form.Item
                            name="usable_store_arr"
                            initialValue={[]}
                            rules={[{ required: true, message: '请选择门店' }]}
                        >
                            <StoreSelector mode="multiple" />
                        </Form.Item>}
                    </Form.Item>}
                    <Form.Item label="可用商品" required style={{ marginBottom: 0 }}>
                        <Form.Item name="usable_goods" initialValue={1} rules={[{ required: true }]}>
                            <Radio.Group>
                                <Radio value={1}>全部可用</Radio>
                                <Radio value={2}>指定可用</Radio>
                                <Radio value={3}>指定不参与</Radio>
                                <Radio value={4}>可用分类</Radio>
                            </Radio.Group>
                        </Form.Item>
                        {[2, 3].includes(data.usable_goods) && <Form.Item
                            name="usable_goods_arr"
                            initialValue={[]}
                            rules={[{ required: true, message: '请选择商品' }]}
                        >
                            <ProductSelector mode="multiple" />
                        </Form.Item>}
                        {data.usable_goods === 4 && <Form.Item
                            name="usable_goods_category_id"
                            initialValue={[]}
                            rules={[{ required: true, message: '请选择分类' }]}
                        >
                            <Cascader
                                options={options}
                                loadData={loadData}
                                multiple
                                maxTagCount="responsive"
                                style={{ width: '50%' }}
                            />
                        </Form.Item>}
                    </Form.Item>
                    <Form.Item label="发放形式" name="is_hand" initialValue={1} rules={[{ required: true }]}>
                        <Radio.Group>
                            <Radio value={1}>手动领取</Radio>
                            <Radio value={2}>系统派发</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <div className="block-title">领用规则</div>
                    <Form.Item label="可领用户" required style={{ marginBottom: 0 }}>
                        <Form.Item name="usable_user" initialValue={1} rules={[{ required: true }]}>
                            <Radio.Group>
                                <Radio value={1}>全部用户</Radio>
                                <Radio value={2}>新用户</Radio>
                                <Radio value={3}>指定购买商品用户</Radio>
                                <Radio value={6}>指定用户</Radio>
                                <Radio value={7}>成功拉新用户</Radio>
                            </Radio.Group>
                        </Form.Item>
                        {data.usable_user === 3 && <Form.Item
                            name="usable_user_goods_arr"
                            initialValue={[]}
                            rules={[{ required: true, message: '请选择商品' }]}
                        >
                            <ProductSelector mode="multiple" />
                        </Form.Item>}
                        {data.usable_user === 6 && <Form.Item
                            name="usable_user_specify_arr"
                            initialValue={[]}
                            rules={[{ required: true, message: '请选择用户' }]}
                        >
                            <UserSelector mode="multiple" />
                        </Form.Item>}
                    </Form.Item>
                    <Form.Item label="每人限领" required>
                        <Space>
                            <Form.Item name="limit_number" rules={[{ required: true, validator: validators.limit_number }]} noStyle>
                                <Input type="number" placeholder="请输入限制张数，最多不超过100张" style={{ width: '234px' }} />
                            </Form.Item>
                            <span>张</span>
                        </Space>
                    </Form.Item>
                    <Form.Item label="优惠叠加(多选)">
                        <Form.Item name="stacking_coupon_arr" initialValue={[]} noStyle>
                            <CouponSelector mode="multiple" />
                        </Form.Item>
                        <div className="form-item-tips">不选择则默认其他优惠券不可与本次优惠券叠加使用</div>
                    </Form.Item>
                    <Form.Item label=" ">
                        <Button
                            onClick={() => window.history.back()}
                            style={{
                                marginRight: 10,
                            }}
                        >
                            取消
                        </Button>
                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </>)}
            </Form>
        </div>
    )
}

export default AddNumeralCoupon